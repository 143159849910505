import { Box } from '@mui/material';
import { BackgroundSplash, Toast } from 'nm-ui-components';

type Props = {
  children: React.ReactNode;
};

export const PageBackground = ({ children }: Props) => (
  <Box
    sx={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <BackgroundSplash />
    <Toast />
    {children}
  </Box>
);
