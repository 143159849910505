import { Box, capitalize, Link, Typography } from '@mui/material';
import { TextField, Button, PageCard, Checkbox } from 'nm-ui-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PageBackground } from 'components/common/PageBackground';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

type ISignInArgs = {
  authority: string;
  defaultPostSignInRedirectUrl: string;
};

type IFormInputs = {
  email: string;
  password: string;
  remember_me: boolean;
};

const schema = yup
  .object({
    email: yup.string().email().required('Please enter your email address'),
    password: yup.string().required('Please enter you password'),
    remember_me: yup.boolean(),
  })
  .required();

export const SignIn = (args: ISignInArgs) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });
  const [searchParams] = useSearchParams();
  const urlClientId = searchParams.get('client_id');
  const urlReturnTo = searchParams.get('return_to');

  useEffect(() => {
    if (urlClientId) {
      localStorage.setItem('clientId', urlClientId);
    }
    if (urlReturnTo) {
      localStorage.setItem('returnTo', urlReturnTo);
    }
  }, [urlClientId, urlReturnTo]);
  const returnTo =
    urlReturnTo || localStorage.getItem('returnTo') || args.defaultPostSignInRedirectUrl;
  const clientId = urlClientId || localStorage.getItem('clientId') || '';
  const signInUrl = `${args.authority}/signin`;

  const onSubmit = (data: IFormInputs) => {
    const payload = {
      email: data.email,
      password: data.password,
      remember_me: data.remember_me || false,
      return_to: returnTo,
      client_id: clientId,
    };
    fetch(signInUrl, {
      body: JSON.stringify(payload),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }).then(async (res) => {
      const body = await res.json();
      if (res.status === 401) {
        setError('email', { message: 'Invalid email or password' });
        setError('password', { message: 'Invalid email or password' });
      } else if (res.status === 200) {
        window.location.href = body.return_to;
      }
    });
  };

  return (
    <PageBackground>
      <PageCard width="400px">
        <Box display="flex" justifyContent="center">
          <Typography marginBottom={1} variant="h1">
            Sign In
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box marginY={2}>
            <Controller
              name={'email'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={Boolean(errors.email)}
                  helperText={capitalize(errors.email?.message || '')}
                  type="email"
                  label="Email"
                  name="email"
                  value={value}
                  onChange={onChange}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Box>
          <Box marginY={2}>
            <Controller
              name={'password'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={Boolean(errors.password)}
                  helperText={capitalize(errors.password?.message || '')}
                  type="password"
                  label="Password"
                  name="password"
                  value={value}
                  onChange={onChange}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Box>
          <Box marginY={2} display="flex" justifyContent="space-between" alignItems="center">
            <Controller
              name={'remember_me'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  onChange={onChange}
                  checked={value}
                  label={<Typography variant="h3">Remember me</Typography>}
                />
              )}
            />
            <Link component={RouterLink} to="/forgot-password">
              Forgot Password?
            </Link>
          </Box>
          <input type="hidden" name="return_to" value={returnTo} />
          <Box display="flex" justifyContent="center">
            <Button variant="contained" type="submit">
              Sign in
            </Button>
          </Box>
        </form>
        <Box marginTop={2} display="flex" justifyContent="center">
          New to Neural Magic?{' '}
          <Link marginLeft={1} component={RouterLink} to="/signup">
            Create Account
          </Link>
        </Box>
      </PageCard>
    </PageBackground>
  );
};
