import { Box, Link, Typography } from '@mui/material';
import { PageCard } from 'nm-ui-components';
import { PageBackground } from 'components/common/PageBackground';
import { Link as RouterLink } from 'react-router-dom';

export const EmailNotVerified = () => {
  return (
    <PageBackground>
      <PageCard width="400px">
        <Box>
          <Typography marginBottom={4} variant="h1">
            Verify Email
          </Typography>
          <Typography marginBottom={4} variant="h3">
            We have not been able to verify your email address:
          </Typography>
          <Typography marginBottom={4} fontWeight="bold" variant="body1">
            rfitzgibbon@neuralmagic.com
          </Typography>
        </Box>
        <Typography marginBottom={4} variant="h3">
          Already verified the email above? Wait a few minutes then try again.
        </Typography>
        <Typography marginBottom={4} variant="h3">
          Not your email?
          <Link marginLeft={2} target="_blank">
            Try with a different email.
          </Link>
        </Typography>
        <Box>
          <Link
            component={RouterLink}
            sx={{ textDecoration: 'none', textTransform: 'uppercase' }}
            variant="h3"
            to="/verify-email"
          >
            Go back
          </Link>
        </Box>
      </PageCard>
    </PageBackground>
  );
};
