import { Box, Link, Typography, capitalize } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { TextField, Button, PageCard, Checkbox, throwToast } from 'nm-ui-components';

import { PageBackground } from 'components/common/PageBackground';
import { useAccountContext } from 'contexts/AccountContext';
import { NewUser } from 'services/AccountService';

const schema = yup
  .object({
    username: yup.string().required(),
    email: yup.string().email().required(),
    password: yup
      .string()
      .required()
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .max(65, 'Password is too long - should be 64 chars maximum.')
      .matches(/^\S.*\S$/, 'Password should not have leading or trailing whitespaces'),
    passwordConfirm: yup
      .string()
      .label('confirm password')
      .required()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    firstName: yup.string().label('first name').required(),
    lastName: yup.string().label('last name').required(),
    hasAcceptedTermsOfService: yup.boolean().required('*Please agree to the terms'),
  })
  .required();

export const SignUp = () => {
  const navigate = useNavigate();
  const { signUpUser } = useAccountContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<NewUser>({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: NewUser) => {
    signUpUser(data).then(async (res) => {
      if (res.generalError) {
        throwToast(`Error Saving: ${res.generalError}`, 'error');
      }
      if (res.fieldErrors) {
        res.fieldErrors.forEach((fieldError) => {
          setError(fieldError.field, { message: fieldError.message });
        });
      }
      if (res.status === 200) {
        navigate('/signin');
      }
    });
  };
  return (
    <PageBackground>
      <PageCard width="400px">
        <Box>
          <Typography marginY={2} variant="h2">
            Account Information
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box marginY={2}>
            <Controller
              name={'email'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={Boolean(errors.email)}
                  helperText={capitalize(errors.email?.message || '')}
                  type="email"
                  label="Email"
                  value={value}
                  onChange={onChange}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoComplete="UserName"
                />
              )}
            />
          </Box>
          <Box marginY={2}>
            <Controller
              name={'password'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={Boolean(errors.password)}
                  helperText={capitalize(errors.password?.message || '')}
                  type="password"
                  label="Password"
                  value={value}
                  onChange={onChange}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoComplete="password"
                />
              )}
            />
          </Box>
          <Box marginY={2}>
            <Controller
              name={'passwordConfirm'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={Boolean(errors.passwordConfirm)}
                  helperText={capitalize(errors.passwordConfirm?.message || '')}
                  type="password"
                  label="Confirm Password"
                  value={value}
                  onChange={onChange}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Box>
          <Box marginY={2}>
            <Controller
              name={'firstName'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={Boolean(errors.firstName)}
                  helperText={capitalize(errors.firstName?.message || '')}
                  label="First Name"
                  value={value}
                  onChange={onChange}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Box>
          <Box marginY={2}>
            <Controller
              name={'lastName'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={Boolean(errors.lastName)}
                  helperText={capitalize(errors.lastName?.message || '')}
                  label="Last Name"
                  value={value}
                  onChange={onChange}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Box>
          <Box marginY={2}>
            <Controller
              name={'username'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={Boolean(errors.username)}
                  helperText={capitalize(errors.username?.message || '')}
                  label="Username"
                  value={value}
                  onChange={onChange}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Box>
          <Box marginY={2}>
            <Controller
              name={'hasAcceptedTermsOfService'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  onChange={onChange}
                  checked={value}
                  errorText={errors.hasAcceptedTermsOfService?.message}
                  label={
                    <div>
                      <div>I have read and agree to the terms of</div>
                      <div>
                        <Link
                          target="_blank"
                          sx={{ textDecoration: 'none' }}
                          href="https://neuralmagic.com/legal/"
                        >
                          Neural Magic Legal
                        </Link>
                      </div>
                    </div>
                  }
                />
              )}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Button variant="contained" onClick={handleSubmit(onSubmit)}>
              Continue
            </Button>
          </Box>
          <Box marginTop={2} display="flex" justifyContent="center">
            Already have an account?{' '}
            <Link marginLeft={1} component={RouterLink} to="/signin">
              Sign In
            </Link>
          </Box>
        </form>
      </PageCard>
    </PageBackground>
  );
};
