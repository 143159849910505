import { Box, capitalize, Typography } from '@mui/material';
import { TextField, Button, PageCard, throwToast } from 'nm-ui-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PageBackground } from 'components/common/PageBackground';
import { useState } from 'react';
import { useAccountContext } from 'contexts/AccountContext';

type FormInputs = {
  email: string;
};

const schema = yup
  .object({
    email: yup.string().email().required('Please enter your email'),
  })
  .required();

export const ForgotPassword = () => {
  const { sendForgotPasswordEmail } = useAccountContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });
  const [hasRequestedEmail, setHasRequestedEmail] = useState(false);
  const onSubmit = ({ email }: FormInputs) => {
    sendForgotPasswordEmail(email).then(async (res) => {
      if (res.status === 204) {
        setHasRequestedEmail(true);
      } else {
        throwToast('Error Sending', 'error');
        setError('email', { message: 'Error sending email' });
      }
    });
  };

  return (
    <PageBackground>
      <PageCard width="400px">
        {!hasRequestedEmail ? (
          <>
            <Box>
              <Typography marginBottom={2} variant="h1">
                Forgot your password?
              </Typography>
              <Typography marginBottom={4} variant="h2">
                No problem.
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box marginY={2}>
                <Controller
                  name={'email'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      error={Boolean(errors.email)}
                      helperText={capitalize(errors.email?.message || '')}
                      type="email"
                      label="Email"
                      value={value}
                      onChange={onChange}
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Box>
              <Box>
                <Button variant="text" onClick={handleSubmit(onSubmit)}>
                  Send password reset email
                </Button>
              </Box>
            </form>
          </>
        ) : (
          <Box>
            <Box>
              <Typography marginBottom={2} variant="h1">
                A new password has been emailed to you.
              </Typography>
              <Typography marginBottom={4} variant="h2">
                If you don’t see your password reset link in your inbox in a couple of minutes,
                check your spam folder.
              </Typography>
            </Box>
            <Button variant="text" onClick={handleSubmit(onSubmit)}>
              Resend password reset email
            </Button>
          </Box>
        )}
      </PageCard>
    </PageBackground>
  );
};
