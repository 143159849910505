export type ApiNewUser = {
  username: string;
  email: string;
  password: string;
  password_confirm: string;
  first_name: string;
  last_name: string;
  has_accepted_terms_of_service: boolean;
};

export type NewUser = {
  username: string;
  email: string;
  password: string;
  passwordConfirm: string;
  firstName: string;
  lastName: string;
  hasAcceptedTermsOfService: boolean;
};

export type ResetPasswordType = {
  password: string;
  passwordConfirm: string;
  token: string;
};

export type ApiResetPasswordType = {
  password: string;
  password_confirm: string;
  token: string;
};

export type NewUserMapValue =
  | 'username'
  | 'email'
  | 'password'
  | 'passwordConfirm'
  | 'firstName'
  | 'lastName'
  | 'hasAcceptedTermsOfService';

export const newUserInfoMap: Record<string, NewUserMapValue> = {
  username: 'username',
  email: 'email',
  password: 'password',
  password_confirm: 'passwordConfirm',
  first_name: 'firstName',
  last_name: 'lastName',
  has_accepted_terms_of_service: 'hasAcceptedTermsOfService',
};

export type ResetPasswordMapValue = 'password' | 'passwordConfirm';

export const resetPasswordMap: Record<string, ResetPasswordMapValue> = {
  password: 'password',
  password_confirm: 'passwordConfirm',
};

export type GenericResponseJson = {
  code: string;
  detail:
    | { loc: string[]; msg: string; type: string }[]
    | { loc: string[]; msg: string; type: string }
    | string;
};

type ApiFieldMap = typeof newUserInfoMap | typeof resetPasswordMap;

type FieldValue<T extends ApiFieldMap, K extends keyof T> = T[K];

const getFieldErrorsFromDetail = <T extends ApiFieldMap>(
  detail:
    | { loc: string[]; msg: string; type: string }[]
    | { loc: string[]; msg: string; type: string },
  apiFieldMap: T
): Array<{ field: FieldValue<T, keyof T>; message: string }> => {
  const apiErrors = Array.isArray(detail) ? detail : [detail];
  return apiErrors.map((error) => {
    const apiField = error.loc[error.loc.length - 1];
    const field = apiFieldMap[apiField] as FieldValue<T, keyof T>;
    return { field, message: error.msg };
  });
};

export type ResetPasswordReturnType = {
  status: number;
  fieldErrors?:
    | {
        field: ResetPasswordMapValue;
        message: string;
      }[]
    | undefined;
  generalError?: string | undefined;
};

export class AccountService {
  _accountManagementServiceApiBaseUrl: string;

  constructor(accountManagementServiceApiBaseUrl: string) {
    this._accountManagementServiceApiBaseUrl = accountManagementServiceApiBaseUrl;
  }

  async sendForgotPasswordEmail(email: string) {
    const response = await fetch(
      `${this._accountManagementServiceApiBaseUrl}/users/forgot-password`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      }
    );
    return {
      status: response.status,
    };
  }

  async signUpUser(data: NewUser) {
    const payload: ApiNewUser = {
      username: data.username,
      email: data.email,
      password: data.password,
      password_confirm: data.passwordConfirm,
      first_name: data.firstName,
      last_name: data.lastName,
      has_accepted_terms_of_service: data.hasAcceptedTermsOfService,
    };
    const response = await fetch(`${this._accountManagementServiceApiBaseUrl}/accounts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseJson: ApiNewUser & GenericResponseJson = await response.json();

    let fieldErrors: { field: NewUserMapValue; message: string }[] | undefined;
    let generalError: string | undefined;
    const detail = responseJson.detail;

    if (response.status === 422 && typeof detail !== 'string') {
      fieldErrors = getFieldErrorsFromDetail(detail, newUserInfoMap);
    } else if (typeof detail === 'string') {
      generalError = detail;
    }

    return {
      status: response.status,
      fieldErrors,
      generalError,
    };
  }

  async resetPassword(data: ResetPasswordType): Promise<ResetPasswordReturnType> {
    const { token, password, passwordConfirm } = data;
    const response = await fetch(
      `${this._accountManagementServiceApiBaseUrl}/users/reset-password`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          password,
          password_confirm: passwordConfirm,
        }),
      }
    );
    if (response.status === 204) {
      return { status: 204 };
    }
    const responseJson: ApiResetPasswordType & GenericResponseJson = await response.json();

    let fieldErrors: { field: ResetPasswordMapValue; message: string }[] | undefined;
    let generalError: string | undefined;
    const detail = responseJson.detail;

    if (response.status === 422 && typeof detail !== 'string') {
      fieldErrors = getFieldErrorsFromDetail(detail, resetPasswordMap);
    } else if (typeof detail === 'string') {
      generalError = detail;
    }

    return {
      status: response.status,
      fieldErrors,
      generalError,
    };
  }
}
