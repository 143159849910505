import { Box, Link, Typography } from '@mui/material';
import { Button, PageCard } from 'nm-ui-components';
import { PageBackground } from 'components/common/PageBackground';

export const VerifyEmail = () => {
  return (
    <PageBackground>
      <PageCard width="400px">
        <Box>
          <Typography marginBottom={4} variant="h1">
            Verify Email
          </Typography>
          <Typography marginBottom={4} variant="h3">
            Please verify the email address associated with your Neural Magic account:
          </Typography>
          <Typography marginBottom={4} fontWeight="bold" variant="body1">
            rfitzgibbon@neuralmagic.com
          </Typography>
        </Box>
        <Box marginBottom={4}>
          <Button variant="contained" onClick={() => alert('good job')}>
            I did this
          </Button>
        </Box>
        <Typography marginBottom={4} variant="h3">
          Not finding the message? Check your spam folder or{' '}
          <Link target="_blank">try with a different email.</Link>
        </Typography>
        <Box>
          <Button variant="text" onClick={() => alert('good job')}>
            Resend Email
          </Button>
        </Box>
      </PageCard>
    </PageBackground>
  );
};
