import * as React from 'react';

import {
  AccountService,
  NewUser,
  NewUserMapValue,
  ResetPasswordType,
  ResetPasswordReturnType,
} from 'services/AccountService';

const initialValue = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signUpUser: (newUser: NewUser) =>
    Promise.resolve() as unknown as Promise<{
      status: number;
      fieldErrors:
        | {
            field: NewUserMapValue;
            message: string;
          }[]
        | undefined;
      generalError: string | undefined;
    }>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sendForgotPasswordEmail: (email: string) =>
    Promise.resolve() as unknown as Promise<{
      status: number;
    }>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resetPassword: (resetPasswordData: ResetPasswordType) =>
    Promise.resolve() as unknown as Promise<ResetPasswordReturnType>,
};
const Context = React.createContext(initialValue);

type AccountContextProps = {
  children: React.ReactNode;
  accountManagementServiceApiBaseUrl: string;
};

export const AccountContext = ({
  children,
  accountManagementServiceApiBaseUrl,
}: AccountContextProps) => {
  const accountService = React.useRef(new AccountService(accountManagementServiceApiBaseUrl));

  const signUpUser = async (newUser: NewUser) => {
    return accountService.current.signUpUser(newUser);
  };

  const sendForgotPasswordEmail = async (email: string) => {
    return accountService.current.sendForgotPasswordEmail(email);
  };

  const resetPassword = async (resetPasswordData: ResetPasswordType) => {
    return accountService.current.resetPassword(resetPasswordData);
  };

  return (
    <Context.Provider value={{ signUpUser, sendForgotPasswordEmail, resetPassword }}>
      {children}
    </Context.Provider>
  );
};

export const useAccountContext = () => {
  return React.useContext(Context);
};
