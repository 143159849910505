import { Config } from './Config';

type JsonConfig = {
  accountManagementServiceApiBaseUrl: string;
  authority: string;
  baseUrl: string;
  defaultPostSignInRedirectUrl: string;
  gitSha: string;
  version: string;
};

type AppConfigGlobal = {
  config?: JsonConfig;
};

interface WindowWithAppConfig extends Window {
  __NM_AUTHENTICATION_SERVICE_UI__?: AppConfigGlobal;
}

const DEFAULT_CONFIG: Config = new Config({
  accountManagementServiceApiBaseUrl: 'https://accounts.neuralmagic.com/v1',
  authority: 'https://accounts.neuralmagic.com/v1',
  baseUrl: 'http://0.0.0.0:3001',
  defaultPostSignInRedirectUrl: 'http://0.0.0.0:3000',
  gitSha: process.env.REACT_APP_AUTHENTICATION_SERVICE_UI_GIT_SHA || 'NONE',
  version: process.env.REACT_APP_AUTHENTICATION_SERVICE_UI_VERSION || 'NONE',
});

const appWindow = window as WindowWithAppConfig;

const ENV_CONFIG = appWindow?.__NM_AUTHENTICATION_SERVICE_UI__?.config
  ? new Config(appWindow.__NM_AUTHENTICATION_SERVICE_UI__.config)
  : undefined;

export const CONFIG: Config = ENV_CONFIG || DEFAULT_CONFIG;
