import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { muiTheme } from 'nm-ui-components';

import { CONFIG } from 'app/configurator';
import PackageInfo from 'pages/packageInfo';
import { SignUp } from 'pages/signup';
import { SignIn } from 'pages/signin';
import { ForgotPassword } from 'pages/forgot-password';
import { ResetPassword } from 'pages/reset-password';
import { VerifyEmail } from 'pages/verify-email';
import { EmailNotVerified } from 'pages/email-not-verified';
import { AccountContext } from 'contexts/AccountContext';

function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <AccountContext
        accountManagementServiceApiBaseUrl={CONFIG.accountManagementServiceApiBaseUrl}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/signup" element={<SignUp />} />
            <Route
              path="/signin"
              element={
                <SignIn
                  authority={CONFIG.authority}
                  defaultPostSignInRedirectUrl={CONFIG.defaultPostSignInRedirectUrl}
                />
              }
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/email-not-verified" element={<EmailNotVerified />} />
            <Route path="/.nm/info" element={<PackageInfo />} />
            <Route path="/" element={<Navigate to="/signin" />} />
          </Routes>
        </BrowserRouter>
      </AccountContext>
    </ThemeProvider>
  );
}

export default App;
