type ConfigConstructor = {
  accountManagementServiceApiBaseUrl: string;
  authority: string;
  baseUrl: string;
  defaultPostSignInRedirectUrl: string;
  gitSha: string;
  version: string;
};

export class Config {
  _accountManagementServiceApiBaseUrl: string;

  _authority: string;

  _baseUrl: string;

  _defaultPostSignInRedirectUrl: string;

  _gitSha: string;

  _version: string;

  constructor({
    accountManagementServiceApiBaseUrl,
    authority,
    baseUrl,
    defaultPostSignInRedirectUrl,
    gitSha,
    version,
  }: ConfigConstructor) {
    this._accountManagementServiceApiBaseUrl = accountManagementServiceApiBaseUrl;
    this._authority = authority;
    this._baseUrl = baseUrl;
    this._defaultPostSignInRedirectUrl = defaultPostSignInRedirectUrl;
    this._gitSha = gitSha;
    this._version = version;
  }

  get accountManagementServiceApiBaseUrl(): string {
    return this._accountManagementServiceApiBaseUrl;
  }

  get authority(): string {
    return this._authority;
  }

  get baseUrl(): string {
    return this._baseUrl;
  }

  get defaultPostSignInRedirectUrl(): string {
    return this._defaultPostSignInRedirectUrl;
  }

  get gitSha(): string {
    return this._gitSha;
  }

  get version(): string {
    return this._version;
  }
}
