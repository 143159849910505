import { Box, Link, Typography } from '@mui/material';
import { TextField, Button, PageCard, throwToast } from 'nm-ui-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PageBackground } from 'components/common/PageBackground';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useAccountContext } from 'contexts/AccountContext';

const schema = yup
  .object({
    password: yup
      .string()
      .required()
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .max(65, 'Password is too long - should be 64 chars maximum.')
      .matches(/^\S.*\S$/, 'Password should not have leading or trailing whitespaces'),
    passwordConfirm: yup
      .string()
      .label('retype password')
      .required()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  .required();

export const ResetPassword = () => {
  const { resetPassword } = useAccountContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<{ password: string; passwordConfirm: string }>({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: { password: string; passwordConfirm: string }) => {
    resetPassword({ ...data, token: token || '' }).then((res) => {
      if (res.generalError) {
        throwToast(`Error Saving: ${res.generalError}`, 'error');
      }
      if (res.fieldErrors) {
        res.fieldErrors.forEach((fieldError) => {
          setError(fieldError?.field, { message: fieldError?.message });
        });
      }
      if (res.status === 200 || res.status === 204) {
        throwToast('New Password Successfully Created, Redirecting To Login', 'success');
        setTimeout(() => {
          navigate('/signin');
        }, 2000);
      }
    });
  };
  return (
    <PageBackground>
      <PageCard width="400px">
        <Box>
          <Typography marginBottom={2} variant="h1">
            Create new password.
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box marginY={2}>
            <Controller
              name={'password'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  type="password"
                  label="Password"
                  value={value}
                  onChange={onChange}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Box>
          <Box marginY={2}>
            <Controller
              name={'passwordConfirm'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={Boolean(errors.passwordConfirm)}
                  helperText={errors.passwordConfirm?.message}
                  type="password"
                  label="Retype Password"
                  value={value}
                  onChange={onChange}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Box>
          <Box marginTop={3} display="flex" justifyContent="center">
            <Button variant="contained" onClick={handleSubmit(onSubmit)}>
              Save new password
            </Button>
          </Box>
          <Box marginTop={3} display="flex" justifyContent="center">
            <Link component={RouterLink} to="/signin">
              Go to Sign In
            </Link>
          </Box>
        </form>
      </PageCard>
    </PageBackground>
  );
};
