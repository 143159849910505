import { CONFIG } from 'app/configurator';

const PackageInfo = () => {
  return (
    <table>
      <tbody>
        <tr>
          <td>gitSha: {CONFIG.gitSha}</td>
        </tr>
        <tr>
          <td>version: {CONFIG.version}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default PackageInfo;
